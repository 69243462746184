export const cppTemplate = `#include<bits/stdc++.h>
using namespace std;
int main(){
  cout << "Hello World";
}`;

export const javaTemplate = `class Code{
  public static void main(String[] args){
    System.out.println("Hello world")
  }
}`;

export const pythonTemplate = `print("Hello World")`;
